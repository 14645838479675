<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :foldable="true"
        :fold-display-number="3"
        :options="searchOptions"
        @search="getDataList"
        @reset="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper">
            <el-button type="primary" @click="openEditDialog()">新增</el-button>
            <!--<el-button type="danger" @click="onDelete()">删除</el-button>-->
        </div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" />
                <el-table-column label="角色名" prop="name" />
                <el-table-column label="描述" prop="description" />
                <el-table-column label="角色状态">
                    <template #default="scope">
                        {{ scope.row.status === 1 ? "启用" : "禁用" }}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="created_at" />
                <el-table-column label="操作" width="150">
                    <template #default="scope">
                        <el-button plain size="mini" type="primary" @click="openEditDialog(scope.row)">
                            编辑
                        </el-button>
                        <el-button plain size="mini" type="danger" @click="onDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <role-edit v-model:show="editDialog.show" :id="editDialog.id" :detail="editDialog.detail" @update="getDataList" />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import useCommonList from "../../../../hooks/useCommonList";
import RoleEdit from "./RoleEdit.vue";
import { DELETE_ROLE, GET_ROLE_LIST } from "@/api/permission";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([{ prop: "name", type: "input", label: "角色名" }]);

let searchOptions = ref({});

let searchForm = ref({
    name: null
});

// 获取数据
let getDataList = () => {
    GET_ROLE_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 增删改
let editDialog = ref({
    show: false,
    id: null,
    detail: null
});

let openEditDialog = (item = null) => {
    editDialog.value = {
        show: true,
        id: item ? item.id : null,
        detail: item
    };
};

let onDelete = (id = null) => {
    // if (id) selectionIds.value = [id];
    ElMessageBox.confirm("确认删除该用户？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
    }).then(() => {
        DELETE_ROLE({ id: id }).then((res) => {
            if (res.data.code === 200) {
                getDataList();
                ElMessage.success("删除成功");
            }
        });
    });
};
</script>
